// React imports

// Remix imports
import { useNavigation } from '@remix-run/react'

// Third party imports

// Generated imports

// App imports
import useFetchersIdle from './useFetchersIdle'

const useFormsDisabled = (ignoreNav?: boolean) => {
  /** Returns True if any form is submitting and therefore all forms should
   *  be disabled
   */
  const fetchersIdle = useFetchersIdle()
  const navigation = useNavigation()

  const navReady = navigation.state === 'idle' || ignoreNav

  return !navReady || !fetchersIdle
}

export default useFormsDisabled
